import axios from 'axios'
/**
 * 通用搜索、列表云函数
 * @param {ICommonQuery} data 请求数据
 * @returns {Promise<ICommonRes>}
 */
const CommonQuery = async (data) => {
    try {
        let res = await c.callFunction({
            name: 'commonquery',
            data
        })
        return res.result
    } catch (e) {
        console.log(e)
        return e
    }

}

/**
 * 单条数据请求，使用的是CMS提供的单模型接口
 * @param {ISingleQuery} data 请求数据，包含id和model
 * @returns {Promise<ISingleRes>}
 */
const ListQuery = async (data) => {
    let { model } = data
    let res = await axios.get(`${process.env.VUE_APP_CMS_API}${model}`)
    return res.data
}

/**
 * 单条数据请求，使用的是CMS提供的单模型接口
 * @param {ISingleQuery} data 请求数据，包含id和model
 * @returns {Promise<ISingleRes>}
 */
const SingleQuery = async (data) => {
    let { model, id } = data
    let res = await axios.get(`${process.env.VUE_APP_CMS_API}${model}/${id}`)
    return res.data
}

export {
    CommonQuery,
    SingleQuery,
    ListQuery
}
